import React, { createElement, useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import MuiTableRow from "@mui/material/TableRow";
import MuiTableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { Chip, LinearProgress } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import TableRow from "./Row";
import { CsvBuilder } from "filefy";
import jsPDF from "jspdf";
import "jspdf-autotable";
import usePagination from "@mui/material/usePagination";
// import CustomDropDown from "../CustomDropDown";
import { ListIcon } from "../../assets/icons";
import Pagination from "@mui/material/Pagination";
import MuiPaginationItem from "@mui/material/PaginationItem";
import {
  arcticMistColor,
  forestShadowColor,
  primaryColor,
  silverMistColor,
  silverSageColor,
  slateGrayColor,
  whisperingSnowColor,
  whiteColor,
} from "../../assets/colors";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

export default function EnhancedTable({
  title,
  columns,
  data,
  toolbar,
  count,
  onChangePage,
  onChangeRowsPerPage,
  loading,
  page,
  from,
  to,
  emptyIcon,
  emptyMessage,
}) {
  const { t } = useTranslation();

  const [rowPerPage, setRowPerPage] = useState(`20 items`);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("calories");
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  let numberPage = Math.ceil(count / 10);
  const { items } = usePagination({
    count: numberPage,
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const exportTableCSV = () => {
    let filteredColumns = columns.filter(
      (col) => "field" in col && col.exportable !== false
    );

    let columnData = filteredColumns.map((col) => col.label).reverse(); // معکوس کردن ترتیب برای RTL

    let rowData = data.map(
      (row) =>
        filteredColumns
          .map((col) => {
            let value;
            if (col.lookup && row[col.field] in col.lookup) {
              value = col.lookup[row[col.field]];
            } else if (col.exportValue) {
              value = col.exportValue(row);
            } else {
              value = row[col.field];
            }

            // جایگزینی مقدار خالی با "-"
            value = value ?? "-";

            // حذف `\n` و خواناتر کردن مقدار
            if (typeof value === "string") {
              value = value.replace(/\n/g, ", "); // یا " | "
            }

            return value;
          })
          .reverse() // معکوس کردن داده‌های ردیف برای RTL
    );

    new CsvBuilder(`report.csv`)
      .setDelimeter(",")
      .setColumns(columnData)
      .addRows(rowData)
      .exportFile();

    setAnchorEl(null);
  };

  async function loadFont() {
    const response = await fetch("/fonts/IRANSansXFaNum-Regular.ttf"); // خواندن فونت از public
    const fontData = await response.arrayBuffer();
    const base64Font = btoa(
      new Uint8Array(fontData).reduce(
        (data, byte) => data + String.fromCharCode(byte),
        ""
      )
    );

    return base64Font;
  }

  async function exportTablePDF() {
    const filteredColumns = columns.filter(
      (col) => "field" in col && col.exportable !== false
    );
    const columnHeaders = filteredColumns.map((col) => col.label).reverse(); // Reverse columns for RTL

    const tableData = data.map(
      (row) =>
        filteredColumns
          .map((col) => {
            let value;
            if (col.lookup && row[col.field] in col.lookup) {
              value = col.lookup[row[col.field]];
            } else if (col.exportValue) {
              value = col.exportValue(row);
            } else {
              value = row[col.field];
            }

            return value ?? "-"; // مقدار خالی را با "-" جایگزین کن
          })
          .reverse() // Reverse row data
    );

    const pdf = new jsPDF("landscape", "mm", "a4");

    const fontBase64 = await loadFont(); // Load your font

    pdf.addFileToVFS("IRANSansXFaNum-Regular.ttf", fontBase64);
    pdf.addFont("IRANSansXFaNum-Regular.ttf", "IRANSansXFaNum", "normal");
    pdf.setFont("IRANSansXFaNum");

    const pageWidth = pdf.internal.pageSize.getWidth();
    const textWidth = pdf.getTextWidth(t("transactions"));

    // Calculate centered position
    const xPosition = pageWidth - textWidth;

    // Write RTL text
    pdf.text(t("transactions"), xPosition, 20, { align: "right" });

    pdf.autoTable({
      head: [columnHeaders], // Use reversed column headers
      body: tableData, // Use processed data with lookup replacements
      startY: 30,
      tableWidth: "auto", // Ensures proper table rendering in RTL
      margin: { left: 10, right: 10 }, // Adjust margin for RTL positioning
      styles: { font: "IRANSansXFaNum", fontSize: 8, halign: "center" }, // Align text to the right
      didParseCell: function (data) {
        data.cell.styles.dir = "rtl"; // Force RTL direction for content
      },
    });

    pdf.save("report.pdf");
  }

  return (
    <Box
      component={Paper}
      variant="outlined"
      sx={{ borderColor: whisperingSnowColor, borderRadius: 2.5 }}
    >
      <Box
        sx={(theme) => ({
          px: 2,
          // display: "flex",
          backgroundColor: "inherit",
          borderRadius: "4px",
          flexDirection: "row",
          gap: "16px",
          justifyContent: "space-between",
          // padding: "24px 16px",
          "@media max-width: 768px": {
            flexDirection: "column",
          },
        })}
      >
        <Box sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ListIcon color={primaryColor} style={{ marginLeft: 10 }} />
            <Typography sx={{ fontSize: 18, fontWeight: 600 }}>
              {title}
            </Typography>
          </Box>
          {toolbar}
          <Chip
            id="basic-button"
            // variant="outlined"
            label="Excel"
            onClick={exportTableCSV}
            // icon={<DocumentDownloadIcon />}
            sx={{
              borderRadius: "6px",
              fontSize: 12,
              // fontWeight: 600,
              height: 40,
              px: 1,
              mr: 1,
            }}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            color="primary"
          />
          <Chip
            id="basic-button"
            // variant="outlined"
            label="PDF"
            onClick={exportTablePDF}
            // icon={<DocumentDownloadIcon />}
            sx={{
              borderRadius: "6px",
              fontSize: 12,
              fontWeight: 300,
              height: 40,
              px: 1,
            }}
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            color="primary"
          />
          {/* <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
              >
                <MenuItem onClick={exportTableCSV}>csv</MenuItem>
                <MenuItem
                  component={PDFDownloadLink}
                  // onClick={exportTablePDF}
                  // document={
                  //   <PdfTemplate
                  //     data={{
                  //       title,
                  //       columns: columns.filter(
                  //         (column) => !column.hasOwnProperty("export")
                  //       ),
                  //       items: data,
                  //       from,
                  //       to,
                  //     }}
                  //   />
                  // }
                  fileName={`${
                    title ? `${title.split(" ").join("")}_` : ""
                  }${from}_${to}`}
                >
                  pdf
                </MenuItem>
              </Menu> */}
        </Box>
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
          <TableHead>
            <MuiTableRow>
              {columns.map((headCell) => (
                <MuiTableCell
                  key={headCell.id}
                  align={headCell.numeric ? "right" : "center"}
                  padding={headCell.disablePadding ? "none" : "normal"}
                  sortDirection={orderBy === headCell.id ? order : false}
                  sx={{
                    fontSize: 12,
                    color: slateGrayColor,
                    borderBottom: "none",
                    minWidth: headCell.minWidth,
                    backgroundColor: arcticMistColor,
                  }}
                >
                  {headCell.label}
                </MuiTableCell>
              ))}
            </MuiTableRow>
          </TableHead>
          <TableBody>
            {data.map((r, index) => (
              <TableRow
                columns={columns}
                data={{ ...r, rowIndex: index }}
                rowsCount={data.length}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ p: 2, display: "flex", justifyContent: "space-between" }}>
        <Typography sx={{ color: slateGrayColor, fontSize: 12 }}>
          نمایش{" "}
          {Math.min(
            count,
            rowPerPage.replace("items", "") * page -
              (rowPerPage.replace("items", "") - data.length)
          )}{" "}
          آیتم از {count} آیتم جدول
        </Typography>
        <Pagination
          variant="outlined"
          count={Math.ceil(count / rowPerPage.replace("items", ""))}
          page={page}
          // siblingCount={0}
          onChange={(event, value) => onChangePage(value)}
          renderItem={(item) => (
            <PaginationItem
              {...item}
              // slots={{
              //   previous: () => (
              //     <ArrowDownIcon style={{ transform: "rotate(90deg)" }} />
              //   ),
              //   next: () => (
              //     <ArrowDownIcon style={{ transform: "rotate(-90deg)" }} />
              //   ),
              // }}
              rowPerPage={rowPerPage.replace("items", "")}
              count={count}
            />
          )}
          sx={{
            "& .MuiPagination-ul": {
              flexDirection: "row-reverse",
            },
          }}
        />
      </Box>
    </Box>
  );
}

const PaginationItem = styled(MuiPaginationItem)((props) => {
  return {
    "&.MuiPaginationItem-root": {
      // flexDirection: "row-reverse",
      height: 27,
      minWidth: 27,
      borderColor: silverMistColor,
      fontWeight: 600,
      // backgroundColor: silverCloudColor,
      // margin: 0,
      // borderRadius: 0,
      // borderTopLeftRadius: props.page === 1 ? 38 : 0,
      // borderBottomLeftRadius: props.page === 1 ? 38 : 0,
      // borderTopRightRadius:
      //   props.page === Math.ceil(props.count / props.rowPerPage) ? 38 : 0,
      // borderBottomRightRadius:
      //   props.page === Math.ceil(props.count / props.rowPerPage) ? 38 : 0,
      fontSize: 11,
      "&.Mui-selected": {
        backgroundColor: primaryColor,
        color: whiteColor,
        // boxShadow: "0px 0px 4.6px 0px rgba(65, 173, 206, 0.76);",
        // borderRadius: 5,
        // height: 27,
        // width: 27,
      },
    },
    "&.MuiPaginationItem-ellipsis": {
      // height: 25,
    },
    "&.MuiPaginationItem-previousNext": {
      borderRadius: "100%",
      marginLeft: 4,
      marginRight: 4,
      minWidth: "auto",
      width: 25,
    },
  };
});
